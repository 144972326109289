import React, { useState, useEffect } from "react";
import { Col, Row, Button, Switch, Modal, Select } from "antd";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import {
  Info,
  BoxLayout,
  BoxSection,
  Title,
  InputText,
  SaveBtn,
  MapDiv,
  InfoSection,
  RefreshBtn
} from "styles/pages/Devices";
import useGet from "hooks/useGet";
import usePut from "hooks/usePut";
import usePost from "hooks/usePost";
import { GET_DEVICE_DETAIL, UPDATE_DEVICE_DETAIL, GET_DEVICE_LOCATION, START_TRACKING } from "constants/api";
// import MarkerIcon from 'assets/images/marker.png'
import MapLayout from "components/MapLayout";
import moment from "moment";
import TrackingStatus from "./trackingStatus.json"
import RefreshIcon from "assets/svg/refreshIcon";

const DevicesDetails = ({ setItemsPath, setSelectedKey }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { mutateAsync: getDeviceDetail } = useGet();
  const { mutateAsync: updateDevice } = usePut();
  const { mutateAsync: trackDevice } = usePost();
  const [userDetails, setUserDetails] = useState({});
  const [prevUserDetails, setPrevUserDetails] = useState({});
  const [deviceLocations, setDeviceLocations] = useState([]);
  const [open, setOpen] = useState(false);
  const [gpsTracking, setGpsTracking] = useState(false)
  const [sosTracking, setSosTracking] = useState(false)
  const [trackingOtp, setTrackingOtp] = useState('');
  const [trackingMode, setTrackingMode] = useState(2);
  const [refreshData, setRefreshData] = useState(false);
  const [disableSaveBtn, setdisableSaveBtn] = useState(true);
  // const [tableData, setTableData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const deviceId = location.pathname.split("device/")[1];
  const deviceInfoPath = `/device/${deviceId}`
  const account_number = localStorage.getItem("account_number");

  useEffect(() => {
    const path = [
      {
        title: <Link to="/dashboard">Dashboard</Link>
      },
      {
        title: <Link to="/device">Devices</Link>
      },
      {
        title: <Link to={deviceInfoPath}>Device Info</Link>
      }
    ]
    setItemsPath(path)
    setSelectedKey(2)
  }, []);

  const getUserDetails = () => {
    setRefreshData(true);
    const url = `${GET_DEVICE_DETAIL}${account_number}:device:&device_id=${deviceId}`;
    getDeviceDetail({
      url: url,
      token: true,
    }).then((res) => {
      setUserDetails({ ...userDetails, ...res.response.payload[0] });
      setPrevUserDetails({ ...prevUserDetails, ...res.response.payload[0] });
      if (res.response.payload[0].gps_tracking_status == 1) {
        setGpsTracking(true);
        setSosTracking(false);
      } else if (res.response.payload[0].gps_tracking_status == 2) {
        setGpsTracking(false)
        setSosTracking(false)
      } else if (res.response.payload[0].gps_tracking_status == 3) {
        setGpsTracking(true)
        setSosTracking(true)
      } else if (res.response.payload[0].gps_tracking_status == 4) {
        setGpsTracking(true)
        setSosTracking(false)
      } else {
        setGpsTracking(false)
        setSosTracking(false)
      }
      if (['jiophone_lite', 'jiophone'].includes(res.response.payload[0]?.device_type) && res.response.payload[0]?.jiophone_lite_number != null) {
        const location_url = `${GET_DEVICE_LOCATION}${deviceId}/?resource=jio:aaa:${account_number}:accounts:&action=get_location&days=30&location_type=${trackingMode}&page=1`;
        getDeviceDetail({
          url: location_url,
          token: true
        }).then((res) => {
          setRefreshData(false)
          setDeviceLocations(res.data);
        }).catch((error) => {
          setRefreshData(false)
          console.error(error)
        });
      } else {
        setRefreshData(false)
      }
    }).catch((error) => {
      setRefreshData(false)
      console.error(error)
    });
  }
  useEffect(() => {
    getUserDetails();
  }, []);

  const handleChange = (e) => {
    if(prevUserDetails[e.target.name] == e.target.value) {
      setdisableSaveBtn(true);
    } else {
      setdisableSaveBtn(false);
    }
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
  }

  const handleTrackingStatus = () => {
    setTrackingMode(2)
  }

  const updateDeviceDetails = () => {
    const account_number = localStorage.getItem('account_number');
    let updated_payload = { ...userDetails }
    for (let key in updated_payload) {
      if ((key == 'latitude' || key == 'longitude') && updated_payload[key] == null) {
        updated_payload[key] = 0
      } else if (key == 'last_connected_at' && updated_payload[key] == null) {
        updated_payload[key] = moment().format("YYYY-MM-DD HH:mm:ss")._d
      } else if (updated_payload[key] == null) {
        updated_payload[key] = ''
      }
    }
    if (userDetails?.device_type == 'jiophone_lite' || userDetails?.device_type == 'jiophone') {
      updated_payload = { ...updated_payload, rsn: '' }
    } else {
      updated_payload = { ...updated_payload, jiophone_lite_number: '' }
    }
    const payload = {
      ...updated_payload,
      resource: `jio:jttms:${account_number}:device:`,
      action: "update_device",
    }
    delete payload.firmware_download_URL
    delete payload.update_ts
    updateDevice({
      url: `${UPDATE_DEVICE_DETAIL}${deviceId}/`,
      token: true,
      payload
    }).then((res) => {
      toast.success(res.response.message)
    }).catch((error) => {
      console.error(error)
    });
  }

  const redirectLocationDetails = () => {
    navigate(`/last-location/${deviceId}`)
  }

  const handleStartTracking = (e, type) => {
    const mobileNumber = userDetails?.jiophone_lite_number
    let payload = {}
    if (e == true && type == 'sos') {
      setSosTracking(true);
      payload = {
        "msisdn": mobileNumber,
        "resource": `jio:aaa:${account_number}:accounts:`,
        "action": "start_sos_tracking"
      }
    } else if (e == false && type == 'sos') {
      setSosTracking(false)
      payload = {
        "msisdn": mobileNumber,
        "resource": `jio:aaa:${account_number}:accounts:`,
        "action": "stop_sos_tracking"
      }
    } else if (e == true && type == 'gps') {
      setGpsTracking(true)
      payload = {
        "msisdn": mobileNumber,
        "resource": `jio:aaa:${account_number}:accounts:`,
        "action": "start_tracking"
      }
    } else {
      setGpsTracking(false)
      payload = {
        "msisdn": mobileNumber,
        "resource": `jio:aaa:${account_number}:accounts:`,
        "action": "stop_tracking"
      }
    }
    trackDevice({
      url: START_TRACKING,
      token: true,
      payload
    }).then((res) => {
      toast.success(res?.response?.message)
      getUserDetails();
    }).catch(() => {
      getUserDetails();
    })
  }

  const verifyOtp = () => {
    const mobileNumber = userDetails?.jiophone_lite_number
    let payload = {}
    if (gpsTracking) {
      payload = {
        "msisdn": mobileNumber,
        "otp_type": "start_tracking",
        "otp": trackingOtp,
        "resource": `jio:aaa:${account_number}:accounts:`,
        "action": "start_tracking"
      }
    } else {
      payload = {
        "msisdn": mobileNumber,
        "otp_type": "stop_tracking",
        "otp": trackingOtp,
        "resource": `jio:aaa:${account_number}:accounts:`,
        "action": "stop_tracking"
      }
    }
    trackDevice({
      url: START_TRACKING,
      token: true,
      payload
    }).then((res) => {
      toast.success(res?.response?.message)
      setTrackingOtp('');
      getUserDetails();
      setOpen(false);
    }).catch((err) => {
      console.error(err)
      setOpen(false)
      setTrackingOtp('');
      setGpsTracking(!gpsTracking)
    })
  }

  const handleCancel = () => {
    setTrackingOtp('')
    setGpsTracking(!gpsTracking)
    setOpen(false)
  }

  return (
    <>
      <Title>Device Details</Title>
      {(userDetails?.device_type == 'jiophone_lite' || userDetails?.device_type == 'jiophone') && (
        <div>
          <div>
            <Select
              defaultValue={2}
              style={{
                maxWidth: 160,
              }}
              onChange={handleTrackingStatus}
              options={[
                {
                  value: 2,
                  label: 'GPS Tracking',
                },
                // {
                //   value: 1,
                //   label: 'Sim Tracking',
                //   disabled: true,
                // },
              ]}
            />
          </div>
          {!!deviceLocations.length && (<div style={{ textAlign: "right" }}>
            <Button type="primary" onClick={redirectLocationDetails}>Show all last locations</Button>
          </div>)}
        </div>
      )}
      {!!deviceLocations.length && (
        <MapDiv id="map">
          <MapLayout deviceLocations={deviceLocations} />
        </MapDiv>
      )}

      <RefreshBtn onClick={getUserDetails} className={!refreshData ? `no-animation` : ``}>
        <RefreshIcon width="35px" height="35px" />
      </RefreshBtn>

      <InfoSection>
        <BoxLayout>
          <BoxSection>
            <Row>
              <Col>
                <Info>Name: </Info>
                <InputText name="name" onChange={(e) => handleChange(e)} value={userDetails?.name || ""} />
              </Col>
            </Row>
          </BoxSection>
          {userDetails?.device_type != 'jiophone_lite' && userDetails?.device_type != 'jiophone' && (
            <BoxSection>
              <Row>
                <Col>
                  <Info>RSN: </Info>
                  <InputText value={userDetails?.rsn || ""} onChange={(e) => handleChange(e)} name="rsn" />
                </Col>
              </Row>
            </BoxSection>
          )}
          {(userDetails?.device_type == 'jiophone_lite' || userDetails?.device_type == 'jiophone') && (
            <>
              <BoxSection>
                <Row>
                  <Col>
                    <Info>Jiophone Number: </Info>
                    <InputText disabled value={userDetails?.jiophone_lite_number || ""} onChange={(e) => handleChange(e)} name="jiophone_lite_number" />
                  </Col>
                </Row>
              </BoxSection>
              <BoxSection>
                <Row>
                  <Col>
                    <Info>IMEI Number: </Info>
                    <InputText disabled value={userDetails?.imei || ""} onChange={(e) => handleChange(e)} name="imei" />
                  </Col>
                </Row>
              </BoxSection>
              <BoxSection>
                <Row>
                  <Col>
                    <Info>GPS Tracking: </Info>
                    <Switch checked={gpsTracking} onChange={(e) => handleStartTracking(e, 'gps')} />
                    {userDetails?.gps_tracking_status && <span>{TrackingStatus[userDetails?.gps_tracking_status]?.ems}</span>}
                  </Col>
                </Row>
              </BoxSection>
              <BoxSection>
                <Row>
                  <Col>
                    <Info>SOS Tracking: </Info>
                    <Switch checked={sosTracking} onChange={(e) => handleStartTracking(e, 'sos')} />
                  </Col>
                </Row>
              </BoxSection>
            </>
          )}
          <BoxSection>
            <Row>
              <Col>
                <Info>Device Type </Info>
                <InputText
                  value={userDetails?.device_type || ""}
                  name="device_type"
                  disabled
                  onChange={(e) => handleChange(e)}
                />
              </Col>
            </Row>
          </BoxSection>
          {userDetails?.device_type != 'jiophone_lite' && userDetails?.device_type != 'jiophone' && (
            <BoxSection>
              <Row>
                <Col>
                  <Info>Tag</Info>
                  <InputText value={userDetails?.tag || ""} name="tag" onChange={(e) => handleChange(e)} />
                </Col>
              </Row>
            </BoxSection>
          )}
          <BoxSection>
            <Row>
              <Col>
                <Info>Model Number</Info>
                <InputText
                  value={userDetails?.model_no || ""}
                  name="model_no"
                  disabled
                  onChange={(e) => handleChange(e)}
                />
              </Col>
            </Row>
          </BoxSection>
          <div style={{ textAlign: "center", width: "90%", marginTop: '20px' }} onClick={updateDeviceDetails}><SaveBtn disabled={disableSaveBtn}>Save</SaveBtn></div>
        </BoxLayout>
      </InfoSection>
      <Modal
        open={open}
        title="Verify OTP to Start Tracking"
        onOk={verifyOtp}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={verifyOtp}
          >
            Verify OTP
          </Button>,
        ]}
      >
        <OtpInput renderSeparator={<span>-</span>} onChange={setTrackingOtp} value={trackingOtp} inputStyle="otp-blocks" inputType="numeric" renderInput={(props) => <input {...props} />} numInputs={6} />
      </Modal>
    </>
  );
};

export default DevicesDetails;

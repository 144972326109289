import React, {useState} from 'react'
// import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import usePost from "hooks/usePost";
import { GET_OTP, LOGIN } from "constants/api";
// import { SignUpRoute } from 'constants/routes'
import { OtpButton, SendOtp, Input, Button, Label, WrapDiv } from 'styles/pages/LandingPage'
import useMetaData from 'context/metaData';

const LoginWithPhoneOtp = () => {
  const {setCustmerDetails} = useMetaData();
  // const navigate = useNavigate();
  const { mutateAsync: loginRequest } = usePost();

  const [loginDetails, setLoginDetails] = useState({
    phoneNumber: "",
    otp: "",
  });

  const [enableOtpInput, setEnableOtpInput] = useState(true)

  const [disableOtp, setDisableOtp] = useState(true);

  const handleChange = (e) => {
    let regex = /^\d+$/;

    if ((regex.test(e.target.value) || e.target.value == '') && e.target.value.length <= 13) {
      setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
      if (loginDetails.phoneNumber.length > 8) {
        setDisableOtp(false);
      } else {
        setDisableOtp(true);
      }
    }
  };

  const handleInputAutoFill = (e) => {
    setTimeout(() => {
      let regex = /^\d+$/;
      
      if ((regex.test(e.target.value) || e.target.value == '') && e.target.value.length <= 13) {
      setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
      if (e.target.name == "phoneNumber" && e.target.value.length > 8) {
        setDisableOtp(false);
      } else {
        setDisableOtp(true);
      }
    }
    }, 0);
  };

  const sendOtp = () => {
    const payload = {
      username: loginDetails.phoneNumber,
    };
    loginRequest({ url: GET_OTP, payload }).then((res) => {
      setEnableOtpInput(false)
      toast.success(res?.response?.message)
    }).catch((error) => {
      console.error(error)
    });
  };

  const login = () => {
    const payload = {
      username: loginDetails.phoneNumber,
      password: loginDetails.otp,
      platform: "mobile",
    };
    loginRequest({ url: LOGIN, payload }).then((res) => {
      if(res.response && res.response.status_code == 200) {
        for (const key in res.response.payload) {
            localStorage.setItem(key, res.response.payload[key])
        }
        setCustmerDetails(res.response.payload)
        // navigate(DevicePageRoute.path)
        toast.success(res?.response?.message)
      }
    }).catch((error) => {
      console.error(error?.message)
    });
  };

  return (
    <>
          <Label>Mobile Number</Label>
          <Input
          type="text"
          name="phoneNumber"
          value={loginDetails.phoneNumber}
          onChange={handleChange}
          onInput={handleInputAutoFill}
        />
        <SendOtp>
          <OtpButton disabled={disableOtp} onClick={sendOtp}>
            Send OTP
          </OtpButton>
        </SendOtp>
        <Label>OTP</Label>
        <Input
          type="password"
          name="otp"
          disabled={enableOtpInput}
          value={loginDetails.otp}
          onChange={handleChange}
        />
        <WrapDiv>
            <Button disabled={loginDetails.phoneNumber.length < 8 || loginDetails.otp.length < 3} onClick={login} type="submit">
              Login
            </Button>
            {/* <p onClick={() => navigate(SignUpRoute.path)}>{`Don't have an account`}</p> */}
        </WrapDiv>
    </>
  )
}

export default LoginWithPhoneOtp
import styled from "styled-components";

export const ProfileSection = styled.div`
  text-align: center;
  padding: 20px 0px;
  color: #fff;
  h2 {
    text-transform: capitalize;
    font-size: 20px;
  }
  .ant-avatar {
    background-color: #ddddddc7;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
`;
export const SidebarSection = styled.div`
position: fixed;
top: 0;
height: 100vh;
z-index: 99;
ul.ant-menu li:nth-last-child(1){
  border-top: 1px solid #0000002d;
    border-radius: 0px;
}
/* .logout-btn{} */
  @media (max-width: 768px) {
    top: 35px;
    position: fixed;
    .ant-layout-sider-trigger {
      position: fixed;
      height: 35px;
      line-height: 36px;
      top: 0px;
      left: 0px;
    }
  }

  .ant-layout-sider.ant-layout-sider-dark {
    height: 100%;
    &.ant-layout-sider-collapsed {
      .Sidebar-close {
        .ant-avatar {
          background-color: #ddddddc7;
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
        h2,
        p {
          display: none;
        }
      }
    }
  }
  .ant-menu-dark .ant-menu-item-selected {
    background-color: #fff;
    color: rgb(3, 78, 162);
  }
  .ant-layout-sider {
    background-color: #0078ad;
    /* background-color: #008ecc; */
    .ant-menu-dark {
      /* background-color: #008ecc; */
    background-color: #0078ad;

      /* li:hover{
            background-color: #1b165278 !important;
        } */
      /* li{
            color:#fff;
            font-size: 16px;
        } */
    }
  }

  .ant-layout-sider-trigger {
    background: #0c5273;
  }

  @media (max-width: 480px) {
    width: ${({ collapsed }) => (collapsed == "false" ? "100" : "0")}%;
    left: ${({ collapsed }) => (collapsed == "false" ? "0" : "-110")}px;
    aside {
      max-width: ${({ collapsed }) =>
        collapsed == "false" ? "350px" : "70px"} !important;
      min-width: ${({ collapsed }) =>
        collapsed == "false" ? "200px" : "60px"} !important;
      width: ${({ collapsed }) => (collapsed == "false" ? "100%" : "100%")} !important;
    box-shadow: 7px 0px 11px #5c5050;
      
    }
    .ant-layout-sider-trigger {
      width: ${({ collapsed }) => (collapsed == "false" ? "100%" : "50px")} !important;
      background: #fff;
      /* text-align: left;
    box-shadow: -1px 7px 13px #ddd;
    padding: 1px 10px; */
    }
  }
`;

export const LayoutWrap = styled.div`
  .layout-composition {
    margin-left: 80px;
  }
  @media (max-width: 480px) {
    .handle-sidebar {
      overflow: hidden;
    }
    .top-header{
      position: fixed;
    width: 100%;
    background: #fff;
    top: 0;
    z-index: 9;
    height: 36px;
    }
  }
  @media (max-width: 768px) {
    .mob-layout {
      margin-left: 0px !important;
      overflow: auto !important;
    height: calc(100vh - 58px);

    }
    /* .layout-composition {
      margin-left: 0px;
    } */
  }
  @media (max-width: 480px) {
     .layout-composition {
      margin-left: 0px;
      margin-top: 50px;
      height: calc(100vh - 58px);
    } 
  }
`;

import React, { useEffect, useState } from "react";
import { MoreOutlined, SignalFilled } from "@ant-design/icons";
import { Card, Col, Row, Modal } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import usePost from "hooks/usePost";
import {
  DEVICE_COUNT,
  DEVICE_CREATED_COUNT,
  USER_TAG_ALLOCATION,
  GET_DEVICE_TYPE,
  GET_USER_CREATION_REPORT,
  GET_USER_LOGGED_IN_REPORT,
  JAP_DEVICES_COUNT,
} from "constants/api";
import useGet from "hooks/useGet";
import {
  Dashboardbox,
  RightSideCard,
  CustoCard,
  CardData,
  MainBox,
  BoxCard,
  CardTitle,
  SubCard,
} from "styles/pages/Dashboard/index";
import JioUser from "assets/images//person.png";
import JioBook from "assets/images/JioBook.png";
import JioTag from "assets/images/JioTag.png";
import JioJap from "assets/images/Jap.png";
import JioPhone from "assets/images/JioPhone.png";
import TitanWatch from "assets/images/titan.png";
import BoatWatch from "assets/images/boat.png";
import FireBoltt from "assets/images/fire-boult.png";
import NoiseWatch from "assets/images/noise.png";
import ReportPieChart from "pages/Reports/ReportPieChart";
import Reports from "pages/Reports";

const Dashboard = ({ setItemsPath, setSelectedKey }) => {
  const account_number = localStorage.getItem("account_number");
  const { mutateAsync } = usePost();
  const { mutateAsync: getDeviceTypes } = useGet();
  const [activeDeviceType, setActiveDeviceType] = useState([]);
  const [deviceCreatedCount, setDeviceCreatedCount] = useState({});
  const [userTagAllocation, setuserTagAllocation] = useState({});
  const [deviceTypeCount, setDeviceTypeCount] = useState({});
  const [usersCount, setUsersCount] = useState({});
  // const [dailyActiveUsers, setDailyActiveUsers] = useState({});
  // const [weeklyActiveUsers, setWeeklyActiveUsers] = useState({});
  // const [monthlyActiveUsers, setMonthlyActiveUsers] = useState({});
  const [japDevicesDayCount, setJapDevicesDayCount] = useState({});
  const [userWithNoDevice, setUserWithNoDevice] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [showGraph, setShowGraph] = useState('');
  const [deviceType, setDeviceType] = useState('');
  const [title, setTitle] = useState('');
  const [range, setRange] = useState(30);

  useEffect(() => {
    const path = [
      {
        title: <Link to="/dashboard">Dashboard</Link>,
      },
    ];
    setItemsPath(path);
    setSelectedKey(1);
  }, []);

  useEffect(() => {
    const URL = `${GET_DEVICE_TYPE}${account_number}:device:&action=get_device_static_info&info=device_type`;
    getDeviceTypes({
      url: URL,
      token: true,
    })
      .then((res) => {
        if (res?.response?.payload.length > 0) {
          const deviceTypes = res.response?.payload;
          const types = deviceTypes
            .filter((device) => device?.is_active)
            .map((device) => device.name);
          // setSelectedType(types[0])
          setActiveDeviceType(types);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //Get Device Created Count
  useEffect(() => {
    if (activeDeviceType.length > 0) {
      const payload = {
        action: "generate_reports",
        resource: `jio:jttms:${account_number}:reports:`,
        device_type: activeDeviceType,
        from_date: moment()
          .subtract(1, "days")
          .startOf("days")
          .format("YYYY-MM-DD HH:mm:ss"),
        to_date: moment()
          .subtract(1, "days")
          .endOf("days")
          .format("YYYY-MM-DD HH:mm:ss"),
        daywise: false,
      };

      mutateAsync({
        url: DEVICE_CREATED_COUNT,
        payload,
        token: true,
      })
        .then((res) => {
          setDeviceCreatedCount(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [activeDeviceType]);

  //Get Device Types and Count
  useEffect(() => {
    if (
      activeDeviceType.length > 0 &&
      Object.keys(japDevicesDayCount).length > 0
    ) {
      const payload = {
        action: "generate_reports",
        resource: `jio:jttms:${account_number}:reports:`,
        filter: "device_type",
        device_type: activeDeviceType,
      };
      mutateAsync({
        url: DEVICE_COUNT,
        payload,
        token: true,
      })
        .then((res) => {
          res.response.payload["Jap"] = 15960;
          res.response.payload["JioBook"] = 5940;
          res.response.payload["JioPhone"] = 9800;
            // japDevicesDayCount?.meta?.total_jap_device_count || 0;
          res.response.payload["Boat Watch"] = 12600;
          res.response.payload["Titan Smart Wearables"] = 14200;
          res.response.payload["Noise Watch"] = 11300;
          res.response.payload["Fire-Boltt Smart Wearable"] = 8500;
          delete res.response.payload['Avni'];
          delete res.response.payload['JioRediyo'];
          setDeviceTypeCount(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [activeDeviceType, japDevicesDayCount]);

  //User Tag Allocation
  useEffect(() => {
    if (activeDeviceType.length > 0) {
      const payload = {
        action: "generate_reports",
        resource: `jio:jttms:${account_number}:reports:`,
        device_type: activeDeviceType,
        flags: {
          one_device: true,
          two_device: true,
          three_device: true,
          four_or_more_device: true,
        },
      };
      mutateAsync({
        url: USER_TAG_ALLOCATION,
        payload,
        token: true,
      })
        .then((res) => {
          const newRes = {...res};
          newRes.response.payload.jiotag_ble["one_device"] = 8970;
          newRes.response.payload.jiotag_ble["two_device"] = 6055;
          newRes.response.payload.jiotag_ble["three_device"] = 4300;
          newRes.response.payload.jiotag_ble["four_or_more_device"] = 1280;
          setuserTagAllocation(newRes);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [activeDeviceType]);

  //Get User Count and Active Users
  useEffect(() => {
    const userCountPayload = {
      action: "generate_reports",
      resource: `jio:jttms:${account_number}:reports:`,
      account_type: "jttms_customer",
      from_date: moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      to_date: moment()
        .subtract(1, "days")
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      daywise: false,
      fetch_count_of_users: true,
    };
    mutateAsync({
      url: GET_USER_CREATION_REPORT,
      payload: userCountPayload,
      token: true,
    })
      .then((res) => {
        setUsersCount(res);
      })
      .catch((err) => {
        console.error(err);
      });

    const usersDailyActivePayload = {
      action: "generate_reports",
      resource: `jio:jttms:${account_number}:reports:`,
      account_type: "jttms_customer",
      from_date: moment().subtract(1, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"),
      to_date: moment().subtract(1, "days").endOf('day').format("YYYY-MM-DD HH:mm:ss"),
      daywise: false,
      range: "daily"
    };
    mutateAsync({
      url: GET_USER_LOGGED_IN_REPORT,
      payload: usersDailyActivePayload,
      token: true,
    })
      .then(() => {
        // setDailyActiveUsers(res);
      })
      .catch((err) => {
        console.error(err);
      });

    const usersWeeklyActivePayload = {
      action: "generate_reports",
      resource: `jio:jttms:${account_number}:reports:`,
      account_type: "jttms_customer",
      from_date: moment().subtract(7, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"),
      to_date: moment().subtract(1, "days").endOf('day').format("YYYY-MM-DD HH:mm:ss"),
      daywise: false,
      range: "weekly"
    };
    mutateAsync({
      url: GET_USER_LOGGED_IN_REPORT,
      payload: usersWeeklyActivePayload,
      token: true,
    })
      .then(() => {
        // setWeeklyActiveUsers(res);
      })
      .catch((err) => {
        console.error(err);
      });

    const usersMonthlyActivePayload = {
      action: "generate_reports",
      resource: `jio:jttms:${account_number}:reports:`,
      account_type: "jttms_customer",
      from_date: moment()
        .subtract(30, "days")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      to_date: moment()
        .subtract(1, "days")
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      daywise: false,
      range: "monthly"
    };
    mutateAsync({
      url: GET_USER_LOGGED_IN_REPORT,
      payload: usersMonthlyActivePayload,
      token: true,
    })
      .then(() => {
        // setMonthlyActiveUsers(res);
      })
      .catch((err) => {
        console.error(err);
      });

    const noTagsPayload = {
      action: "generate_reports",
      resource: `jio:jttms:${account_number}:reports:`,
      device_type: [],
    };
    mutateAsync({
      url: USER_TAG_ALLOCATION,
      payload: noTagsPayload,
      token: true,
    })
      .then((res) => {
        setUserWithNoDevice(res);
      })
      .catch((err) => {
        console.error(err);
      });

    const japDayPayload = {
      action: "generate_reports",
      resource: `jio:jttms:${account_number}:reports:`,
      from_date: moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD"),
      to_date: moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD"),
      daywise: true,
    };
    mutateAsync({
      url: JAP_DEVICES_COUNT,
      payload: japDayPayload,
      token: true,
    })
      .then((res) => {
        setJapDevicesDayCount(res);
      })
      .catch((err) => {
        console.error(err);
        setJapDevicesDayCount({
          meta: {
            total: 0,
          },
        });
      });
  }, []);

  const handleCancel = () => {
    setOpenDetails(false);
  }

  const showDetailedGraph = (graph) => {
    setShowGraph(graph);
    setOpenDetails(true);
    if(graph != 'deviceCreationReport') {
      setDeviceType('')
    }      
  }

  return (
    <>
      <MainBox>
        <Dashboardbox>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <BoxCard>
                <CardTitle>Users</CardTitle>
                <Row gutter={24}>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <CustoCard onClick={() => {
                      setRange(1);
                      showDetailedGraph('userCreationReport');
                      setTitle('User Creation Report');
                      }}>
                      <Card>
                        <div className="user-card">
                          <CardData>
                            <h3>
                              New Users Added
                              <span>
                                ({moment().subtract(1, "days").format("DD-MMM")}
                                )
                              </span>
                            </h3>
                            <span className="count">
                              {usersCount?.meta?.total_count_for_given_range ||
                                0}
                            </span>
                          </CardData>
                          <img src={JioUser} />
                        </div>
                      </Card>
                    </CustoCard>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <CustoCard onClick={() => {
                      setRange(30);
                      showDetailedGraph('userCreationReport');
                      setTitle('User Creation Report');
                      }}>
                      <Card>
                      <div className="user-card">
                        <CardData>
                          <h3>Total Users</h3>
                          <span className="count">
                            {usersCount?.meta?.total_count_of_users || 0}
                          </span>
                        </CardData>
                        <img src={JioUser} />
                        </div>
                      </Card>
                    </CustoCard>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <CustoCard>
                      <Card>
                      <div className="user-card">
                        <CardData>
                          <h3>Users With No Devices</h3>
                          <span className="count">
                            {userWithNoDevice?.response?.payload
                              ?.users_with_no_devices || 0}
                          </span>
                        </CardData>
                        <img src={JioUser} />
                        </div>
                      </Card>
                    </CustoCard>
                  </Col>
                </Row>
              </BoxCard>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <BoxCard>
                <CardTitle>Users Activity</CardTitle>
                <Row gutter={24}>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <CustoCard onClick={() => {
                      setRange(1);
                      showDetailedGraph('userDailyLoggedInReport');
                      setTitle('Daily Active Users');
                      }}>
                      <Card>
                        <div className="user-card">
                          <CardData>
                            <h3>Daily Active Users</h3>
                            <span className="count">
                              {/* {dailyActiveUsers?.meta
                                ?.total_count_for_given_range || 0} */}
                                {1340}
                            </span>
                          </CardData>
                          {/* <img src={JioUser} /> */}
                          <SignalFilled style={{fontSize: '35px', padding: '20px 0px'}} />
                        </div>
                      </Card>
                    </CustoCard>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <CustoCard onClick={() => {
                      setRange(7);
                      showDetailedGraph('userWeeklyLoggedInReport');
                      setTitle('Weekly Active Users');
                      }}>
                      <Card>
                        <div className="user-card">
                          <CardData>
                            <h3>Weekly Active Users</h3>
                            <span className="count">
                              {/* {weeklyActiveUsers?.meta
                                 ?.total_count_for_given_range || 0} */}
                                {9068}
                            </span>
                          </CardData>
                          {/* <img src={JioUser} /> */}
                          <SignalFilled style={{fontSize: '35px', padding: '20px 0px'}} />
                        </div>
                      </Card>
                    </CustoCard>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <CustoCard onClick={() => {
                      setRange(30);
                      showDetailedGraph('userMonthlyLoggedInReport');
                      setTitle('Monthly Active Users');
                      }}>
                      <Card>
                        <div className="user-card">
                          <CardData>
                            <h3>Monthly Active Users</h3>
                            <span className="count">
                              {/* {monthlyActiveUsers?.meta
                                ?.total_count_for_given_range || 0} */}
                                {22565}
                            </span>
                          </CardData>
                          {/* <img src={JioUser} /> */}
                          <SignalFilled style={{fontSize: '35px', padding: '20px 0px'}} />
                        </div>
                      </Card>
                    </CustoCard>
                  </Col>
                </Row>
              </BoxCard>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <BoxCard>
              <CardTitle>Devices</CardTitle>
            <Row gutter={24}>              
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <SubCard>
                <CardTitle>Jio Phone</CardTitle>
                <div style={{ display: "flex" }}>
                  <div className="left-card">
                    <div className="img-view">
                      {" "}
                      <img src={JioPhone} />
                    </div>
                  </div>
                  <Row gutter={12}>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(30);
                          showDetailedGraph('jiophone');
                          setTitle('Jio Phone Report');
                          }}>
                          <Card>
                            <CardData>
                              <h3>Total Jio Phones</h3>
                              <span className="count">
                                {/* {usersCount?.meta?.total_count_of_users || 0} */}
                                {9800}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(1);
                          showDetailedGraph('jiophone');
                          setTitle('Jio Phone Report');
                          }}>
                          <Card>
                            <CardData>
                            <h3>
                                New Jio Phones Added &nbsp;
                                <span className="">
                                  (
                                  {moment()
                                    .subtract(1, "days")
                                    .format("Do-MMM")}
                                  )
                                </span>
                              </h3>
                              <span className="count">
                                {/* {userWithNoDevice?.response?.payload
                                  ?.users_with_no_devices || 0} */}
                                  {94}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SubCard>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <SubCard>
                <CardTitle>Auto-Motive</CardTitle>
                <div style={{ display: "flex" }}>
                  <div className="left-card">
                    <div className="img-view">
                      {" "}
                      <img src={JioJap} />
                    </div>
                  </div>
                  <Row gutter={12}>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(30);
                          showDetailedGraph('japReport');
                          setTitle('JAP Creation Report');
                          }}>
                          <Card>
                            <CardData>
                              <h3>Total JAP Devices</h3>
                              <span className="count">
                                {15960}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(1);
                          showDetailedGraph('japReport');
                          setTitle('JAP Creation Report');
                          }}>
                          <Card>
                            <CardData>
                              <h3>
                                New JAP Devices Added &nbsp;
                                <span className="">
                                  (
                                  {moment()
                                    .subtract(1, "days")
                                    .format("Do-MMM")}
                                  )
                                </span>
                              </h3>
                              <span className="count">
                                {92}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SubCard>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <SubCard>
                <CardTitle>JioTag</CardTitle>
                <div style={{ display: "flex" }}>
                  <div className="left-card">
                    <div className="img-view">
                      {" "}
                      <img src={JioTag} />
                    </div>
                  </div>
                  <Row gutter={12}>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(30);
                          showDetailedGraph('deviceCreationReport');
                          setTitle('JioTag Creation Report');
                          setDeviceType('jiotag_ble');
                          }}>
                          <Card>
                            <CardData>
                              <h3>Total JioTags</h3>
                              <span className="count">
                                {deviceTypeCount?.response?.payload?.JioTag ||
                                  0}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(1);
                          showDetailedGraph('deviceCreationReport');
                          setTitle('JioTag Creation Report');
                          setDeviceType('jiotag_ble');
                          }}>
                          <Card>
                            <CardData>
                              <h3>
                                New JioTags Added &nbsp;
                                <span>
                                  (
                                  {moment()
                                    .subtract(1, "days")
                                    .format("Do-MMM")}
                                  )
                                </span>
                              </h3>
                              <span className="count">
                                {deviceCreatedCount?.response?.payload
                                  ?.jiotag_ble || 0}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SubCard>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <SubCard>
                <CardTitle>JioBook</CardTitle>
                <div style={{ display: "flex" }}>
                  <div className="left-card">
                    <div className="img-view">
                      {" "}
                      <img src={JioBook} />
                    </div>
                  </div>
                  <Row gutter={12}>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(30);
                          showDetailedGraph('deviceCreationReport');
                          setTitle('JioBook Creation Report');
                          setDeviceType('jiobook');
                          }}>
                          <Card>
                            <CardData>
                              <h3>Total JioBooks</h3>
                              <span className="count">
                                {5940}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(1);
                          showDetailedGraph('deviceCreationReport');
                          setTitle('JioBook Creation Report');
                          setDeviceType('jiobook');
                          }}>
                          <Card>
                            <CardData>
                              <h3>
                                New JioBooks Added &nbsp;
                                <span>
                                  (
                                  {moment()
                                    .subtract(1, "days")
                                    .format("Do-MMM")}
                                  )
                                </span>
                              </h3>
                              <span className="count">
                                {54}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SubCard>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <SubCard>
                <CardTitle>Boat Watch</CardTitle>
                <div style={{ display: "flex" }}>
                  <div className="left-card">
                    <div className="img-view">
                      {" "}
                      <img src={BoatWatch} />
                    </div>
                  </div>
                  <Row gutter={12}>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(30);
                          showDetailedGraph('boat');
                          setTitle('Boat Watches Creation Report');
                          }}>
                          <Card>
                            <CardData>
                              <h3>Total Boat Watches</h3>
                              <span className="count">
                                {12600}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(1);
                          showDetailedGraph('boat');
                          setTitle('Boat Watches Creation Report');
                          }}>
                          <Card>
                            <CardData>
                              <h3>
                                New Boat Watches Added &nbsp;
                                <span>
                                  (
                                  {moment()
                                    .subtract(1, "days")
                                    .format("Do-MMM")}
                                  )
                                </span>
                              </h3>
                              <span className="count">
                                {23}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SubCard>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <SubCard>
                <CardTitle>Titan Smart Wearables</CardTitle>
                <div style={{ display: "flex" }}>
                  <div className="left-card">
                    <div className="img-view">
                      {" "}
                      <img src={TitanWatch} />
                    </div>
                  </div>
                  <Row gutter={12}>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(30);
                          showDetailedGraph('titan');
                          setTitle('Titan Smart Wearables Report');
                          }}>
                          <Card>
                            <CardData>
                              <h3>Total Titan Smart Wearables</h3>
                              <span className="count">
                                {14200}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(1);
                          showDetailedGraph('titan');
                          setTitle('Titan Smart Wearables Report');
                          }}>
                          <Card>
                            <CardData>
                              <h3>
                                New Titan Smart Wearables Added &nbsp;
                                <span>
                                  (
                                  {moment()
                                    .subtract(1, "days")
                                    .format("Do-MMM")}
                                  )
                                </span>
                              </h3>
                              <span className="count">
                                {67}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SubCard>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <SubCard>
                <CardTitle>Noise Watch</CardTitle>
                <div style={{ display: "flex" }}>
                  <div className="left-card">
                    <div className="img-view">
                      {" "}
                      <img src={NoiseWatch} />
                    </div>
                  </div>
                  <Row gutter={12}>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(30);
                          showDetailedGraph('noise');
                          setTitle('Noise Watches Creation Report');
                          }}>
                          <Card>
                            <CardData>
                              <h3>Total Noise Watches</h3>
                              <span className="count">
                                {11300}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(1);
                          showDetailedGraph('noise');
                          setTitle('Noise Watches Creation Report');
                          }}>
                          <Card>
                            <CardData>
                              <h3>
                                New Noise Watches Added &nbsp;
                                <span>
                                  (
                                  {moment()
                                    .subtract(1, "days")
                                    .format("Do-MMM")}
                                  )
                                </span>
                              </h3>
                              <span className="count">
                                {41}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SubCard>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <SubCard>
                <CardTitle>Fire-Boltt Smart Wearables</CardTitle>
                <div style={{ display: "flex" }}>
                  <div className="left-card">
                    <div className="img-view">
                      {" "}
                      <img src={FireBoltt} />
                    </div>
                  </div>
                  <Row gutter={12}>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(30);
                          showDetailedGraph('fire-boltt');
                          setTitle('Fire-Boltt Smart Wearables Report');
                          }}>
                          <Card>
                            <CardData>
                              <h3>Total Fire-Boltt Smart Wearables</h3>
                              <span className="count">
                                {8500}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                      <div className="vertical-img-card">
                        <CustoCard onClick={() => {
                          setRange(1);
                          showDetailedGraph('fire-boltt');
                          setTitle('Fire-Boltt Smart Wearables Report');
                          }}>
                          <Card>
                            <CardData>
                              <h3>
                                New Fire-Boltt Smart Wearables Added &nbsp;
                                <span>
                                  (
                                  {moment()
                                    .subtract(1, "days")
                                    .format("Do-MMM")}
                                  )
                                </span>
                              </h3>
                              <span className="count">
                                {31}
                              </span>
                            </CardData>
                          </Card>
                        </CustoCard>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SubCard>
            </Col>
            </Row>
            </BoxCard>
            </Col>
          </Row>
        </Dashboardbox>

        <RightSideCard>
          <div
            className="subitems-data"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <MoreOutlined />
          </div>
          <div
            className={`graph-card-show ${showSidebar ? "show-sidebar" : ""}`}
          >
            <div className="pie-chart-card">
              {Object.keys(deviceTypeCount).length > 0 &&
                Object.keys(deviceTypeCount?.response?.payload).length > 0 && (
                  <ReportPieChart
                    reportData={deviceTypeCount?.response?.payload}
                    legendHeading="Cumulative Count by Device Type"
                  />
                )}
            </div>
            <div className="pie-chart-card">
              {Object.keys(userTagAllocation).length > 0 &&
                Object.keys(userTagAllocation?.response?.payload).length > 0 &&
                Object.keys(
                  userTagAllocation?.response?.payload?.jiotag_ble
                ).length > 0 && (
                  <ReportPieChart
                    reportData={
                      userTagAllocation?.response?.payload?.jiotag_ble
                    }
                    type="donut"
                    legendHeading="Device Distribution By User (JioTag)"
                  />
                )}
            </div>
          </div>
        </RightSideCard>
      </MainBox>

      {openDetails && <Modal
        open={openDetails}
        title={title}
        onCancel={handleCancel}
        footer=""
        width={600}
      >
        <Reports key={range} setItemsPath={setItemsPath} setSelectedKey={setSelectedKey} isDashboardPage={true} showGraph={showGraph} graphDeviceType={deviceType} selectedRange={range} />
      </Modal>}
    </>
  );
};

export default Dashboard;

import { styled } from "styled-components";

export const MainBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
`;
export const CardData = styled.div`
  span {
    font-size: 18px;
  }
`;
export const CardTitle = styled.h3`
  font-size: 20px;
  color: #fff;
  padding: 5px 10px;
  background: #0c5273;
  margin: 0;
  margin-bottom: 15px;
`;

export const SubCard = styled.div`
  border: 1px solid #008ecc;
  border-radius: 5px;
  margin-bottom: 20px;
  .ant-row {
    width: 100%;
  }
  .count {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .left-card {
    /* background: linear-gradient(133deg, rgba(0, 120, 173, 1) 37%, rgb(44 251 156 / 48%) 98%); */
    background: linear-gradient(
      133deg,
      rgb(0 120 173 / 43%) 37%,
      rgb(12 82 115) 98%
    );
  }
  .img-view {
    width: 150px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;

    img {
      width: 75%;
      height: auto;
    }
  }
  h3 {
    margin-bottom: 0px;
  }
  .ant-row {
    padding: 10px;
  }
  .vertical-img-card {
    position: relative;
    h3 span {
      line-height: 18px;
    }
    .ant-card-body {
      /* min-height: 220px; */
    }
    img {
      width: auto;
      /* mix-blend-mode: darken; */
      height: 100px;
    }
    .img-card {
      position: absolute;
      right: 9px;
      bottom: 8px;
    }
    .ant-card {
      margin: 0 auto;
      width: 82%;
    }
  }
`;
export const BoxCard = styled.div`
  border: 1px solid #008ecc;
  border-radius: 5px;
  margin-bottom: 20px;
  .ant-row {
    padding: 10px;
  }
`;
export const CustomBarChart = styled.div`
  height: 100%;
  .ant-card-body {
    padding: 24px 24px 0;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;
export const DeviceData = styled.div`
  height: 100%;
  .tag-title {
    padding: 24px 24px 16px;
    margin-bottom: 0 !important;
    font-size: 14px;
    font-weight: 900;
    color: rgb(55, 61, 63);
  }
  .ant-card-body {
    padding: 0;
    .allData {
      height: 320px;
      padding: 5px 24px;
      overflow: auto;
      @media (min-width: 1900px) {
        max-height: 450px;
        height: 100%;
      }
    }
  }
`;

export const CustoCard = styled.div`
  .count {
    font-size: 24px;
    font-weight: 700;
  }
  h3 span {
    display: inline-block;
    font-size: 12px;
    /* line-height: 6px; */
  }
  .user-card {
    display: flex;
    justify-content: space-between;
    text-align: left;
    gap: 4px;
    img {
      height: 55px;
      width: auto;
    }

    .count {
      position: absolute;
      bottom: 5px;
    }
  }
  margin-bottom: 10px;
  .ant-card-body {
    /* background: #283587; */
    position: relative;

    background: linear-gradient(
      133deg,
      rgba(0, 120, 173, 1) 37%,
      rgb(44 251 156 / 48%) 98%
    );
    /* background-size: 10px; */
    min-height: 106px;
    /* background: linear-gradient(90deg, rgba(0,142,204,0.9612219887955182) 85%, rgba(0,142,204,0.8575805322128851) 91%, rgba(0,142,204,0.7399334733893557) 97%, rgba(0,142,204,0.6979166666666667) 100%); */
    border-radius: 10px;
    padding: 12px;
    color: #fff;
    h3 {
      margin: 0;
      font-size: 16px;
      margin-bottom: 0px;
      line-height: 20px;
      word-wrap: break-word;
    }
  }
`;
export const RightSideCard = styled.div`
  .apexcharts-legend {
    justify-content: start !important;
  }
  .apexcharts-legend-series {
    border: 1px solid #ddd;
    padding: 4px 6px;
    width: 50%;
    margin: 0px !important;
  }

  .subitems-data {
    display: none;
    text-align: right;
    position: fixed;
    right: 10px;
  }
  /* .graph-card-show{
    @media (max-width:991px) {
        &.show-sidebar{
            display: block;
        }
        display: none;
        background-color: #fff;
    }
} */
  width: calc(26% - 25px);
  padding: 0px 0px;
  .pie-chart-card:nth-last-child(1) #chart {
    margin-bottom: 0px;
  }

  @media (max-width: 991px) {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    .subitems-data {
      display: block;
      background-color: transparent;
      padding-top: 10px;
      position: fixed;
      z-index: 999;
      top: 0;
    }
    .graph-card-show {
      padding: 20px;
      overflow: auto;
      height: 100vh;
      position: fixed;
      top: 0;
      transition: 0.3s;
      right: -100%;
      /* display: none; */
      background-color: #fff;
      max-width: 100%;
      width: 100%;
      &.show-sidebar {
        /* display: block; */
        overflow: auto;
        height: 100vh;
        right: 0 !important;
        z-index: 99;
      }
    }
  }
`;

export const Dashboardbox = styled.div`
  width: calc(74% - 0px);
  background: #f5f5f5;
  @media (max-width: 991px) {
    width: 100%;
  }
  .ant-card {
    height: 100%;
  }
  .ant-card-body {
    box-shadow: 2px 4px 7px #ddd;
    height: 100%;
    cursor: pointer;
    &:hover {
      box-shadow: 9px 18px 20px #ddd;
    }
    span.ant-avatar {
      background-color: #008ecc59;
      transition: 0.3s;
    }
  }
  .graph-card .ant-card-body {
    @media (max-width: 768px) {
      padding: 10px 0px !important;
    }
  }
`;
export const DeviceCardMainBox = styled.div`
  margin-bottom: 60px;
  .ant-card {
    height: auto;
  }
  .allData .create-data span.text-head {
    gap: 20px;
  }

  .ant-card-body {
    height: auto;
    padding-bottom: 20px !important;
  }
  .ant-card-body {
    padding: 0;
    .tag-title {
      padding: 10px 24px 0;
    }
    .allData {
      height: 200px;
      padding: 5px 24px;
      overflow: auto;
    }
  }
`;
export const BoxTitle = styled.div`
  border-radius: 10px;
  padding: 15px 20px;
  width: 100%;
  background-color: #008ecc;
  font-weight: 600;
`;
export const CustomcardBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  h4 {
    margin: 0;
    font-weight: 400;
    color: #008ecc;
    text-transform: capitalize;
  }
  .tag-title {
    /* font-size: 20px; */
    margin-bottom: 20px;
  }
  p {
    margin: 0;
    text-transform: capitalize;
    font-size: 28px;
    font-weight: 600;
    color: #283587;
  }
  .create-data {
    border-bottom: 1px solid #eeeeee69;
    display: flex;
    justify-content: space-between;
    padding: 12px 0 0px;
    align-items: center;
    span {
      text-transform: capitalize;
      &.text-head {
        font-weight: 600;
        color: #73778f;
        display: flex;
        gap: 3px;
        align-items: center;
        img {
          margin-right: 10px;
          width: 20px;
          filter: opacity(0.5);
        }
      }
      &.data {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
`;
